import React, {useState} from "react"
import BackgroundImage from 'gatsby-background-image'
import {graphql, useStaticQuery} from "gatsby";

const PopUp = (props) => {
    const [email, setEmail] = useState('');

    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "popup-desktop.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        `);
    return (
        <div style={{zIndex: "40"}} data-sal="slide-left" data-sal-delay="700" data-sal-easing="easeInOutSine" className="fixed bottom-0 lg:right-0 lg:w-96 w-full lg:px-0">
            <BackgroundImage fluid={data.desktop.childImageSharp.fluid}>
                <div className="lg:px-10 px-4">
                    <a href="#" className="text-white font-bold text-lg lg:float-left lg:mt-10 mt-2 float-right" onClick={(e) => {e.preventDefault(); props.handlePopupClose()  }}>X</a>
                    <h2 className="lg:pt-12 pt-5 lg:pb-6 pb-2 text-blue text-center lg:text-3xl text-2xl font-raleway font-bold">¿Quieres hacer crecer tu empresa?</h2>
                    <p className="text-white lg:pb-6 pb-4 text-center lg:text-lg text-md font-lato">Recibe nuestro <strong className="font-black">modelo </strong>
                        de <strong className="font-black">crecimiento exponencial </strong>
                        para tu negocio y conoce nuestro
                        servicio de <strong className="font-black">asesoría premium</strong>.</p>
                    <label className="bg-white mx-auto text-gray w-full block lg:mb-10 mb-4 rounded py-2 px-2" htmlFor="">
                        <input value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Tu email" className="outline-none w-full" type="text"/>
                    </label>
                    <button onClick={() => props.handleSubmit({email})} className="bg-blue text-white rounded-lg w-full py-2 text-lg">Quiero crecer con Bansō</button>
                    <p className="text-white text-xs lg:text-xs lg:pt-5 text-center py-4 lg:pb-6">Tu información nunca será compartida con terceros.</p>
                </div>

            </BackgroundImage>
        </div>
    )
}

export default PopUp;
