/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Img from "gatsby-image";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import SiteTitle from "./SiteTitle";
import PopUp from "./popup";
import SiteSubtitle from "./SiteSubtitle";


const Layout = ({ children }) => {

  const [showPopup, setShowPopup] = useState(true);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      desktop: file(relativePath: { eq: "banso-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      arrowUp: file(relativePath: { eq: "arrow-up.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      desicionManager: file(relativePath: { eq: "decision-manager.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      loga: file(relativePath: { eq: "loga.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)


  const desicionManager = data.desicionManager.childImageSharp.fluid
  const loga = data.loga.childImageSharp.fluid
  const handleSubmit = async (data) => {
    let form = new FormData();
    form.append('email', data.email);

    await fetch('/send.php', {
      method: 'post',
      body: form,
    });
    setShowPopup(false);
  };

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <AnchorLink to="/#home" stripHash className="sticky bottom-10 absolute z-20 left-0 mt-4 lg:mt-0 lg:px-20 px-4 float-right" title="Inicio"><Img fixed={data.arrowUp.childImageSharp.fixed}/></AnchorLink>

        <div className="bg-gray-200 py-4 md:flex lg:flex justify-between items-center lg:px-20 mt-20">
          <div className="lg:w-2/6 md:w-2/6 px-8 lg:px-0">
            <SiteSubtitle color={'text-yellow lg:text-left md:text-left text-center pt-6'} subtitle={`ALIANZAS ESTRATÉGICAS`}/>
          </div>
          <div className="flex flex-row justify-around items-center md:w-4/6 lg:w-4/6 w-full overflow-hidden mx-auto py-10">
            <div className="w-2/3 lg:w-1/3" data-sal="slide-right" data-set-duration="1000" data-sal-delay="700" data-sal-easing="ease-in-out-bounce">
              <Img  fluid={desicionManager} alt="Desicion Manager" />
            </div>
            <div className="w-1/3 lg:w-1/3 lg:mt-0" data-sal="slide-left" data-set-duration="1000" data-sal-delay="700" data-sal-easing="ease-in-out-bounce">
              <Img  fluid={loga} alt="LOGA" />
            </div>
          </div>
        </div>

        <footer className="bg-blue text-white font-lato lg:flex justify-between items-center lg:px-20 px-4 py-10">
          <a href="/" className="w-36"><Img className="w-2/12 lg:w-full mx-auto" loading="eager" alt="banso fintax lab" fluid={data.desktop.childImageSharp.fluid}/></a>
          <p className="pt-4 lg:pt-0 lg:text-left text-center"> © {new Date().getFullYear()} Banso Fintax Lab By Florencia Studio</p>
          <ul className="flex mt-4 lg:mt-0 justify-around items-center">
            <li><a href="https://www.linkedin.com/company/banso-fintaxlab" target="_blank" className="mr-4"><Img fixed={data.instagram.childImageSharp.fixed}/></a></li>
          </ul>
        </footer>
      </div>
      { showPopup ? (<PopUp handleSubmit={handleSubmit} handlePopupClose={handlePopupClose}/>) : (<p/>) }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
