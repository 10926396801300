import {graphql, useStaticQuery} from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import Img from "gatsby-image";

import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = ({ siteTitle }) => {

    const [showMenu, setShowMenu] = useState(false);
    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "banso-logo.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        `
    )

    return (
        <header style={{zIndex: "100"}} className="flex justify-between items-center lg:px-20 px-4 lg:py-14 py-8 absolute z-40 w-full">
            <a href="/" className="w-40"><Img className="w-full" loading="eager" alt="banso fintax lab" fluid={data.desktop.childImageSharp.fluid}/></a>
            <a href="#" className="lg:hidden cursor-pointer" onClick={(event) => { event.preventDefault(); setShowMenu(!showMenu) }}>
                <span className="w-10 h-1 bg-yellow block"/>
                <span className="w-10 h-1 mt-3 bg-yellow block"/>
                <span className="w-10 h-1 mt-3 bg-yellow block"/>
            </a>
                <ul className={`lg:flex absolute ${showMenu ? '' : 'hidden'} lg:relative bg-blue lg:w-auto w-full px-4 lg:px-auto lg:bg-transparent lg:h-auto h-screen top-0 lg:top-auto lg:left-auto left-0 py-20 lg:py-0 lg:px-0 justify-around text-yellow font-poppins font-semibold text-md uppercase`}>
                    <li className="py-4 lg:py-0 lg:mr-2 cursor-pointer right-1 top-0 absolute lg:hidden"><a onClick={() => { setShowMenu(!showMenu) }}>
                        <svg className="w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </a>
                    </li>
                    <li onClick={() => { setShowMenu(!showMenu) }} className="hover:text-white transition-all py-4 lg:py-0 lg:mr-2"><AnchorLink title="Inicio" to="/#home" stripHash>Inicio</AnchorLink></li>
                    <li onClick={() => { setShowMenu(!showMenu) }} className="hover:text-white transition-all py-4 lg:py-0 lg:mr-2 lg:ml-2"><AnchorLink title="Nosotros" to="/#nosotros" stripHash>Nosotros</AnchorLink></li>
                    <li onClick={() => { setShowMenu(!showMenu) }} className="hover:text-white transition-all py-4 lg:py-0 lg:mr-2 lg:ml-2"><AnchorLink title="Servicios" to="/#servicios" stripHash>Servicios</AnchorLink></li>
                    <li onClick={() => { setShowMenu(!showMenu) }} className="hover:text-white transition-all py-4 lg:py-0 lg:mr-2 lg:ml-2"><AnchorLink title="Equipo" to="/#equipo" stripHash>Equipo</AnchorLink></li>
                    <li onClick={() => { setShowMenu(!showMenu) }} className="hover:text-white transition-all py-4 lg:py-0 lg:ml-2"><AnchorLink title="Contacto" to="/#contacto" stripHash>Contacto</AnchorLink></li>
                </ul>
        </header>
    )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
